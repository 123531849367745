import React, { useEffect, useState } from "react";
import LightLogo from "../assets/light_logo.jpg";
import DarkLogo from "../assets/dark_logo.jpg";
import CSIOfficial from '../assets/csi-light.jpg'
import { NavLink } from "react-router-dom";

export const Header = () => {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const [darkMode, setDarkMode] = useState(JSON.parse(localStorage.getItem("darkMode")) || false);
  const [modalOpen, setModalOpen] = useState(false);

  const activeClasses = "ml-1 block py-2 px-3 text-lg text-lt-blue dark:text-lt-blue hover:dark:text-lt-blue";
  const inactiveClasses = "ml-1 block py-2 px-3 text-lg text-dt-blue dark:text-white hover:text-lt-blue hover:dark:text-lt-blue";

  const lightModeIcon = <svg viewBox="0 0 24 24" fill="none" className="w-6 h-6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
  <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" className="fill-lt-blue group-hover:fill-white stroke-lt-blue group-hover:stroke-white"></path>
  <path d="M12 4v1M17.66 6.344l-.828.828M20.005 12.004h-1M17.66 17.664l-.828-.828M12 20.01V19M6.34 17.664l.835-.836M3.995 12.004h1.01M6 6l.835.836" className="stroke-lt-blue group-hover:stroke-white"></path></svg>

  const darkModeIcon = <svg viewBox="0 0 24 24" fill="none" class="w-6 h-6"><path d="M17.715 15.15A6.5 6.5 0 0 1 9 6.035C6.106 6.922 4 9.645 4 12.867c0 3.94 3.153 7.136 7.042 7.136 3.101 0 5.734-2.032 6.673-4.853Z" class="fill-lt-white group-hover:fill-white" fill-rule="evenodd" clip-rule="evenodd"></path><path d="m17.715 15.15.95.316a1 1 0 0 0-1.445-1.185l.495.869ZM9 6.035l.846.534a1 1 0 0 0-1.14-1.49L9 6.035Zm8.221 8.246a5.47 5.47 0 0 1-2.72.718v2a7.47 7.47 0 0 0 3.71-.98l-.99-1.738Zm-2.72.718A5.5 5.5 0 0 1 9 9.5H7a7.5 7.5 0 0 0 7.5 7.5v-2ZM9 9.5c0-1.079.31-2.082.845-2.93L8.153 5.5A7.47 7.47 0 0 0 7 9.5h2Zm-4 3.368C5 10.089 6.815 7.75 9.292 6.99L8.706 5.08C5.397 6.094 3 9.201 3 12.867h2Zm6.042 6.136C7.718 19.003 5 16.268 5 12.867H3c0 4.48 3.588 8.136 8.042 8.136v-2Zm5.725-4.17c-.81 2.433-3.074 4.17-5.725 4.17v2c3.552 0 6.553-2.327 7.622-5.537l-1.897-.632Z" class="group-hover:fill-white fill-white"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M17 3a1 1 0 0 1 1 1 2 2 0 0 0 2 2 1 1 0 1 1 0 2 2 2 0 0 0-2 2 1 1 0 1 1-2 0 2 2 0 0 0-2-2 1 1 0 1 1 0-2 2 2 0 0 0 2-2 1 1 0 0 1 1-1Z" class="fill-white group-hover:fill-white"></path></svg>

  useEffect(() => {
    localStorage.setItem("darkMode", JSON.stringify(darkMode));
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
    <nav className="relative w-90h top-0 left-0 bg-white shadow-md z-50">
      <div className="relative max-w-screen-xl flex flex-wrap items-center justify-between mx-auto">
        <a className="flex items-center space-x-3" href="/">
            <img
              alt="csi-wieect-logo"
              loading="lazy"
              width="400" 
              height="400"
              decoding="async"
              data-nimg="1"
              className="h-auto max-h-[400px] cursor-pointer"
              src={CSIOfficial}
              style={{ color: "transparent" }}
            />
          </a>
        </div>
      </nav>
      <nav className="sticky top-0 z-20 bg-white shadow-bluish dark:bg-dt-blue dark:shadow-xl mt-2px">
        <div className="relative max-w-screen-xl flex items-center justify-between mx-auto">
          <a className="flex items-center space-x-3 rtl:space-x-reverse" href="/">
            { darkMode ?
              (<img
                alt="csi-wieect-logo"
                loading="lazy"
                width="198"
                height="200"
                decoding="async"
                data-nimg="1"
                className="logo-img max-h-full cursor-pointer"
                src={DarkLogo}
                style={{ color: "transparent" }}
              />)
              :
              (<img
                alt="csi-wieect-logo"
                loading="lazy"
                width="198"
                height="200"
                decoding="async"
                data-nimg="1"
                className=" logo-img max-h-full cursor-pointer"
                src={LightLogo}
                style={{ color: "transparent" }}
              />)
            }
          </a>
          <div className="flex justify-end md:order-4 space-x-4 md:space-x-0 rtl:space-x-reverse items-center pr-4 md:pr-4">
            <button type="button" className="inline-flex items-center px-[14px] h-10 mr-2 justify-center text-sm focus:outline-none font-medium rounded-lg text-white border-2 border-lt-blue bg-lt-blue dark:border-lt-blue dark:bg-dt-blue" onClick={openModal}>
              Contact Us
            </button>
            <button type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm focus:outline-none font-medium rounded-lg text-white border-2 border-lt-blue hover:bg-lt-blue dark:border-lt-blue dark:bg-dt-blue dark:hover:bg-lt-blue group" onClick={() => setDarkMode(!darkMode)}>
              { darkMode ? darkModeIcon : lightModeIcon}
            </button>
            <button data-collapse-toggle="navbar-sticky" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-search" aria-expanded="false" onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}><span className="sr-only">Open main menu</span>
              <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"></path>
              </svg>
            </button>
          </div>
          <div className={`items-center justify-between ${isMobileMenuVisible ? '' : 'hidden'} w-full md:flex md:w-auto md:order-1`} id="navbar-search">
            <ul className="po_absolute flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg text-dt-blue bg-white md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 dark:bg-dt-blue dark:text-white md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <NavLink to="/" className={({ isActive }) => (isActive ? activeClasses : inactiveClasses)}
                onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/events" className={({ isActive }) => (isActive ? activeClasses : inactiveClasses)}
                onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}>
                  Events
                </NavLink>
              </li>
              <li>
                <NavLink to="/faculty" className={({ isActive }) => (isActive ? activeClasses : inactiveClasses)}
                onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}>
                  Faculty
                </NavLink>
              </li>
              <li>
                <NavLink to="/committee" className={({ isActive }) => (isActive ? activeClasses : inactiveClasses)}
                onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}>
                  Committee
                </NavLink>
              </li>
              <li>
                <NavLink to="/devs" className={({ isActive }) => (isActive ? activeClasses : inactiveClasses)}
                onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}>
                  Devs
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        {modalOpen && (
          <div className="fixed inset-0 z-50 bg-lt-blue/50" onClick={closeModal} style={{ pointerEvents: "auto" }} aria-hidden="true"></div>
        )}

        <div
          role="dialog"
          id="radix-:r1:"
          aria-describedby="radix-:r3:"
          aria-labelledby="radix-:r2:"
          className={`fixed left-[50%] top-[50%] z-50 grid max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border border-lt-blue bg-white p-6 shadow-lg duration-200 ${
            modalOpen ? "animate-in" : "animate-out"
          } dark:bg-dt-blue sm:rounded-lg w-[90%] sm:w-[30rem] md:max-w-[50rem]`}
          tabIndex="-1"
          style={{ pointerEvents: "auto", display: modalOpen ? "block" : "none" }}
        >
          <div className="w-full">
            <div className="max-h-[24rem] md:w-full">
              <h2>
                <div className="text-center text-3xl font-bold text-dt-blue dark:text-white">Contact Us</div>
              </h2>
              <h4>
                <div className="block w-full text-center text-dt-blue md:text-sm">
                  <p className="text-sm dark:text-white">We will get back to you as soon as possible.</p>
                </div>
              </h4>
              <form onSubmit={closeModal} method="POST" action="https://docs.google.com/forms/d/e/1FAIpQLSc7QrMUvbtkJUQDco2_Y0Aehl7TvbBcLYrgSa7Zh6SOvlaHDQ/formResponse">
                <label className="font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-md text-dt-blue dark:text-white">Name</label>
                <input
                  className="mt-2 mb-7 w-full rounded-md border border-lt-blue p-2 outline-none dark:bg-white"
                  type="text"
                  name="entry.2005620554"
                />
                <label className="font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-md text-dt-blue dark:text-white">Email</label>
                <input
                  className="mb-7 mt-2 flex w-full items-center justify-center rounded-md border border-lt-blue p-2 text-[#0A192F] outline-none dark:bg-white"
                  type="email"
                  name="entry.1045781291"
                />
                <label className="font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-md text-dt-blue dark:text-white">Message</label>
                <textarea
                  className="mb-7 mt-2 flex h-[6rem] w-full items-center justify-center rounded-md border border-lt-blue p-2 placeholder-teal-900 outline-none dark:bg-white"
                  name="entry.1166974658"
                ></textarea>
                <div className="flex w-full items-center justify-center">
                  <button
                    className="relative min-w-fit rounded-md border-0 border-lt-blue/50 bg-lt-blue/50 py-2 px-4 text-dt-blue outline-none duration-700 dark:border-none dark:border-lt-blue/50 dark:bg-lt-blue/50 dark:text-white"
                    type="submit"
                  >
                    Submit
                    <span className="absolute bottom-0 right-0 h-full w-full min-w-fit rounded-md border-[2px] border-lt-blue bg-lt-blue p-2 text-white duration-200 hover:translate-x-[-3px] hover:translate-y-[-3px] dark:border-[2px] dark:border-lt-blue dark:bg-lt-blue dark:text-white">
                      Submit
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <button
            type="button"
            className="absolute right-4 top-4 rounded-2xl opacity-70 text-white bg-lt-blue dark:hover:opacity-50 p-2 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none"
            onClick={closeModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-x h-4 w-4"
            >
              <path d="M18 6 6 18"></path>
              <path d="m6 6 12 12"></path>
            </svg>
            <span className="sr-only">Close</span>
          </button>
        </div>
      </nav>
    </>
  );
};
