import React from 'react'

export const Footer = () => {
    return (
        <div className="bg-white bg-opacity-5 py-6 backdrop-blur-md dark:bg-dt-blue dark:bg-opacity-5 md:py-10 dark:shadow-2xl">
            <div className="container flex flex-col items-center gap-y-2 text-dt-blue text-opacity-80 dark:text-white dark:text-opacity-40">
                <p>All rights reserved @CSI-WIET <span className="text-lt-blue text-opacity-80 dark:text-lt-blue dark:text-opacity-80">2023 - 2024 -present</span></p>
                <div className="mx-auto flex w-full max-w-[1200px] items-center justify-center gap-4">
                    <svg width="292" height="12" viewBox="0 0 292 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M291.774 6L286 0.226497L280.226 6L286 11.7735L291.774 6ZM0 7L286 7V5L0 5V7Z" fill="#FFA2A2"/>
                    </svg>
                    <p className="text-center leading-7">A Student Body Chapter of<br className="block md:hidden"/><a href="https://www.watumull.edu/2024/index.php" target="_blank" rel="noreferrer" className="pl-2 text-lt-blue text-opacity-80 underline dark:text-dt-lavender dark:text-opacity-80">Watumull Institute of Engineering and Technology</a></p>
                    <svg width="292" height="12" viewBox="0 0 292 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM292 5H6V7H292V5Z" fill="#FFA2A2"/>
                    </svg>
                </div>
            </div>
        </div>
    )
}
