import React, { useState, useEffect } from 'react';
import { EventPoster } from '../components';

export const EventsPage = () => {
  const [selectedYear, setSelectedYear] = useState('2024-25');
  const [eventsData, setEventsData] = useState(null);

  // Fetch data from db.json
  useEffect(() => {
    fetch('/data/db.json')
      .then((response) => response.json())
      .then((data) => {
          setEventsData(data?.data || {});
          console.log('Fetched events data:', data)
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleYearClick = (year) => {
    setSelectedYear(year);
  };

  // Extracting events and workshops for the selected year
  const eventsForYear = eventsData?.events?.[selectedYear] || [];
  const workshopsForYear = eventsData?.workshops?.[selectedYear] || [];

  // Filter events based on eventType for 2024-25
  const interCollegeEvents = eventsForYear.filter(
    (event) => event.eventType === 'inter-college'
  );
  const intraCollegeEvents = eventsForYear.filter(
    (event) => event.eventType === 'intra-college'
  );
  const funEvents = eventsForYear.filter(
    event => event.eventType === 'fun-event'
  );



  return (
    <div className="">
        <div className="container py-[24px] lg:py-[32px]">
        {/* Header Section */}
        <div className="py-10 md:pt-8">
          <div className="flex items-center justify-center" >
            <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM91 5H6V7H91V5Z" fill="#FFA2A2" />
            </svg>
            <p className="text-dt-blue dark:text-white text-3xl font-semibold capitalize tracking-wide mx-4">
              Events
            </p>
            <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M90.7735 6L85 0.226497L79.2265 6L85 11.7735L90.7735 6ZM0 7L85 7V5L0 5V7Z" fill="#FFA2A2" />
            </svg>
          </div>
          <div className="text-center pb-10">
            <p className="mt-2 text-dt-blue dark:text-white text-lg font-semibold capitalize tracking-wide mx-16">
              We have proudly hosted numerous events. Looking back, here are some of the events organized by CSI!
            </p>
          </div>

           {/* Year Selector */}
           <div className="min-h-screen flex flex-col items-center">
            <div className="text-center p-6" >
              <button
                onClick={() => handleYearClick('2023-24')}
                className={`font-bold py-2 px-4 rounded ${selectedYear === '2023-24' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} hover:bg-blue-700`}
              >
                2023-24
              </button>
              <button
                onClick={() => handleYearClick('2024-25')}
                className={`ml-4 font-bold py-2 px-4 rounded ${selectedYear === '2024-25' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} hover:bg-blue-700`}
              >
                2024-25
              </button>
            </div>

            {/* Events Display */}
            {/* Display events based on selected year */}
            {selectedYear !== '2023-24' ? (
            <>
              {/* Inter-college Events */}
              <div className="my-8 w-full text-center">
                {/* Inter-college Section */}
                <div className="flex items-center justify-center">
                  <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM91 5H6V7H91V5Z" fill="#FFA2A2" />
                  </svg>
                  <p className="text-dt-blue dark:text-white text-3xl font-semibold capitalize tracking-wide mx-4">
                    Inter College Events
                  </p>
                  <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M90.7735 6L85 0.226497L79.2265 6L85 11.7735L90.7735 6ZM0 7L85 7V5L0 5V7Z" fill="#FFA2A2" />
                  </svg>
                </div>
                {interCollegeEvents.length > 0 ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-2 mx-0 lg:mx-36  place-items-center">
                    {interCollegeEvents.map((event, index) => (
                      <EventPoster
                        key={index}
                        image={event.image}
                        title={event.title}
                        eventYear={selectedYear}
                        eventId={event.id}
                      />
                    ))}
                  </div>
                ) : (
                  <p className="text-dt-blue dark:text-white mt-4">
                    No inter-college events found for {selectedYear}
                  </p>
                )}
              </div>

              {/* Intra-college Events */}
              <div className="my-8 w-full text-center">
                <div className="flex items-center justify-center">
                  <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM91 5H6V7H91V5Z" fill="#FFA2A2" />
                  </svg>
                  <p className="text-dt-blue dark:text-white text-3xl font-semibold capitalize tracking-wide mx-4">
                    Intra-College Events
                  </p>
                  <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M90.7735 6L85 0.226497L79.2265 6L85 11.7735L90.7735 6ZM0 7L85 7V5L0 5V7Z" fill="#FFA2A2" />
                  </svg>
                </div>
                {intraCollegeEvents.length > 0 ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
                    {intraCollegeEvents.map((event, index) => (
                      <EventPoster
                        key={index}
                        image={event.image}
                        title={event.title}
                        eventYear={selectedYear}
                        eventId={event.id}
                      />
                    ))}
                  </div>
                ) : (
                  <p className="text-dt-blue dark:text-white mt-4">
                    No intra-college events found for {selectedYear}
                  </p>
                )}
              </div>

              {/* Fun Events */}
              <div className="my-8 w-full text-center">
                <div className="flex items-center justify-center">
                  <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM91 5H6V7H91V5Z" fill="#FFA2A2" />
                  </svg>
                  <p className="text-dt-blue dark:text-white text-3xl font-semibold capitalize tracking-wide mx-4">
                    Fun Events
                  </p>
                  <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M90.7735 6L85 0.226497L79.2265 6L85 11.7735L90.7735 6ZM0 7L85 7V5L0 5V7Z" fill="#FFA2A2" />
                  </svg>
                </div>
                {funEvents.length > 0 ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
                    {funEvents.map((event, index) => (
                      <EventPoster
                        key={index}
                        image={event.image}
                        title={event.title}
                        eventYear={selectedYear}
                        eventId={event.id}
                      />
                    ))}
                  </div>
                ) : (
                  <p className="text-dt-blue dark:text-white mt-4">
                    No fun events found for {selectedYear}
                  </p>
                )}
              </div>
            </>
          ) : (
            // For other years, display events without categorization
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {eventsForYear.length > 0 ? (
                eventsForYear.map((event, index) => (
                  <EventPoster
                    key={index}
                    image={event.image}
                    title={event.title}
                    eventYear={selectedYear}
                    eventId={event.id}
                  />
                ))
              ) : (
                <p className="text-dt-blue dark:text-white mt-4">
                  No events found for {selectedYear}
                </p>
              )}
            </div>
          )}

          </div>

          {/* Sponsor Section */}
          {/*
          <div className="py-4 md:pt-8 md:pb-20 mx-4">
            <div className="mt-8 py-10 px-10 flex flex-col gap-4 md:gap-8 lg:gap-16 text-white bg-lt-blue dark:bg-gray-800">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="relative col-span-1 md:col-span-2 lg:col-span-1 lg:col-start-3" data-aos="fade-right" data-aos-duration="3000">
                  <div className="rounded border-white dark:border-white border-2">
                    <img src="sponsor-image.jpg" className="-bottom-4 -left-4 rounded border-2 border dark:border-white relative" alt="Sponsor" />
                  </div>
                </div>
                <div className="col-span-1 flex flex-col gap-8 md:col-span-2 lg:col-span-2">
                  <div className="flex items-center" data-aos="fade-down-right" data-aos-duration="3000">
                    <svg width="48" height="12" viewBox="0 0 48 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM46.05 7C46.6023 7 47.05 6.55228 47.05 6C47.05 5.44772 46.6023 5 46.05 5V7ZM6 7H46.05V5H6V7Z" fill="#FFA2A2" />
                    </svg>
                    <p className="text-xl font-semibold capitalize tracking-wide mx-4">
                      Sponsor
                    </p>
                    <svg width="47" height="12" viewBox="0 0 47 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M46.8485 6L41.075 0.226497L35.3014 6L41.075 11.7735L46.8485 6ZM1.02498 5C0.472698 5 0.0249825 5.44772 0.0249825 6C0.0249825 6.55228 0.472698 7 1.02498 7V5ZM41.075 5L1.02498 5V7L41.075 7V5Z" fill="#FFA2A2" />
                    </svg>
                  </div>
                  <div className="flex flex-col gap-2 text-sm md:text-base" data-aos="fade-left" data-aos-duration="3000">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, doloremque sed esse, neque repellendus vitae fugit necessitatibus fugiat quidem, a modi mollitia numquam iusto ab alias deserunt reprehenderit. Esse animi, est tenetur voluptates cum, quasi corporis atque eos dolor, quisquam natus repudiandae magnam inventore nisi libero soluta dicta eum officiis facere? Nulla quas ipsam quod fugit voluptates perspiciatis ab.
                  </div>
                </div>
              </div>
            </div>
          </div>
          */}

          {/* Workshops Section - Show only if there are workshops */}
          {workshopsForYear.length > 0 && (
            <div>
              <div className="flex items-center justify-center">
                <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM91 5H6V7H91V5Z" fill="#FFA2A2" />
                </svg>
                <p className="text-dt-blue dark:text-white text-3xl font-semibold capitalize tracking-wide mx-4">
                  Workshops
                </p>
                <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M90.7735 6L85 0.226497L79.2265 6L85 11.7735L90.7735 6ZM0 7L85 7V5L0 5V7Z" fill="#FFA2A2" />
                </svg>
              </div>
              <div className="min-h-screen flex flex-col items-center">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {workshopsForYear.map((event, index) => (
                    <EventPoster
                      key={index}
                      image={event.image}
                      title={event.title}
                      eventYear={selectedYear}
                      eventId={event.id}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}


          {/* More components or sections */}
        </div>
      </div>
    </div>
  );
};
