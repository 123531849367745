import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 

export const Committee = () => {
  const [selectedYear, setSelectedYear] = useState('2024-25');
  const [committeeData, setCommitteeData] = useState([]);

  useEffect(() => {
    fetch('/data/db.json') 
      .then(response => response.json())
      .then(data => {
        if (data?.data?.committee) {
          setCommitteeData(data.data.committee); 
          console.log(data.data.committee); 
        }
      })
      .catch(error => console.error("Error fetching data:", error));
  }, []);

  // Function to handle year button clicks
  const handleYearClick = (year) => {
    setSelectedYear(year);
  };

  // Get profiles based on selected year
  const profiles = committeeData[selectedYear] || [];

    return (
      <div className="">
        <div className="container py-[24px] lg:py-[32px]">
          <div className="py-10 px-10 md:pt-8">
            <div className="flex items-center justify-center" >
              <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM91 5H6V7H91V5Z" fill="#FFA2A2" />
              </svg>
              <p className="text-dt-blue dark:text-white text-3xl font-semibold capitalize tracking-wide mx-4">
                Meet Our Team
              </p>
              <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M90.7735 6L85 0.226497L79.2265 6L85 11.7735L90.7735 6ZM0 7L85 7V5L0 5V7Z" fill="#FFA2A2" />
              </svg>
            </div>
            {/* Year Selector */}
            <div className="text-center p-6" data-aos="fade" data-aos-duration="1500">
              {/*
              <button
                onClick={() => handleYearClick('2023-24')}
                className={`font-bold py-2 px-4 rounded ${selectedYear === '2023-24' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} hover:bg-blue-700`}
              >
                2023-24
              </button>
               */}
              <button
                onClick={() => handleYearClick('2024-25')}
                className={`font-bold py-2 px-4 rounded ${selectedYear === '2024-25' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} hover:bg-blue-700`}
              >
                2024-25
              </button>
            </div>
            <div className="md:pt-8 md:pb-20 flex items-center justify-center" data-aos="zoom-out" data-aos-duration="1000">
              {profiles && profiles.length > 0 && profiles[0].highlight ? (
                <img
                  src={profiles[0].highlight}
                  alt="Highlight"
                  className="w-5/6 h-fit object-cover rounded-lg"
                />
              ) : (
                <p>No highlight available.</p>
              )}
            </div>
          </div>
          <div className="py-10 md:pt-8">
            <div className="flex items-center justify-center" >
              <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM91 5H6V7H91V5Z" fill="#FFA2A2" />
              </svg>
              <p className="text-dt-blue dark:text-white text-3xl font-semibold capitalize tracking-wide mx-4">
                Team Members
              </p>
              <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M90.7735 6L85 0.226497L79.2265 6L85 11.7735L90.7735 6ZM0 7L85 7V5L0 5V7Z" fill="#FFA2A2" />
              </svg>
            </div>
            <div className="text-center pb-10">
              <p className="mt-2 text-dt-blue dark:text-white text-lg font-semibold capitalize tracking-wide mx-16">
                We have proudly assigned team members. Looking back, here are team memebrs of CSI!
              </p>
            </div>
            <div className="flex flex-wrap justify-center">
              {profiles.map((profile, index) => {
                if (index === 0) return null
                const displayIndex = index + 1;
                return (
                  <div key={displayIndex} className="max-w-lg rounded overflow-hidden shadow-lg m-4" data-aos="fade-up" data-aos-duration="800">
                    <div className="w-full h-80 bg-gray-200 flex items-center justify-center">
                      <img className="h-full w-full object-cover m-1" src={profile.image} alt={profile.name} />
                    </div>
                    <div className="flex items-center justify-between m-1">
                      <div className="border border-gray-300 p-2 rounded-lg mr-2 px-10">
                        <div className="flex flex-col text-center item-center justify-center">
                          <h2 className="text-lg font-semibold text-dt-blue dark:text-white">{profile.name}</h2>
                          <h3 className="text-md text-dt-blue dark:text-white">{profile.position}</h3>
                        </div>
                      </div>
                      <div className="border border-gray-300 p-2 rounded-lg">
                        <Link to={profile.linkedin} target="_blank" rel="noopener noreferrer">
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/8/81/LinkedIn_icon.svg"
                            alt="LinkedIn"
                            className="w-6 h-6"
                        />
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
}
