import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Committee, DevelopersPage, EventDetails, EventsPage, FacultyPage, HomePage } from '../pages'

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={ <HomePage/> }/>
      <Route path='/events' element={ <EventsPage/> }/>
      <Route path="/events/:eventYear/:eventId" element={ <EventDetails/> } />
      <Route path='/committee' element={ <Committee/> }/>
      <Route path='/faculty' element={ <FacultyPage/> }/>
      <Route path='/devs' element={ <DevelopersPage/> }/>
    </Routes>
  )
}