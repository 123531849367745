import React from 'react';
import { Link } from 'react-router-dom';

export const EventPoster = ({ image, title, eventYear, eventId }) => {
  return (
    <div className="relative max-w-[48rem] rounded overflow-hidden shadow-lg m-4" data-aos="fade-up" data-aos-duration="1500">
      <img src={image} alt={title} className="w-full h-full object-cover rounded shadow-lg" />
      <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
        <Link to={`/events/${eventYear}/${eventId}`} className="bg-lt-blue text-white py-2 px-4 rounded hover:bg-dt-blue border border-lt-blue dark:border-lt-blue dark:bg-dt-blue dark:hover:bg-blue-700">Know More</Link>
      </div>
    </div>
  );
};

export default EventPoster;
