import { AppRoutes, Footer, Header } from "./components";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className="font-manr bg-white font-courier-new dark:bg-dt-blue">
      <Header/>
      <AppRoutes/>
      <Footer/>
    </div>
  );
}

export default App;
