import React from 'react'
import { useState, useEffect } from 'react';

export const FacultyPage = () => {

  const [facultyData, setFacultyData] = useState([]);

  useEffect(() => {
    fetch('/data/db.json') 
      .then(response => response.json())
      .then(data => {
        if (data?.data?.faculty) {  
          setFacultyData(data.data.faculty);  
          console.log(data.data.faculty); 
        }
      })
      .catch(error => console.error("Error fetching data:", error));
  }, []);

  return (
    <div className="">
        <div className="container py-[24px] lg:py-[32px]">
        <div className="py-10 md:pt-8">
          <div className="flex items-center justify-center" >
            <svg
              width="91"
              height="12"
              viewBox="0 0 91 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM91 5H6V7H91V5Z"
                fill="#FFA2A2"
              />
            </svg>
            <p className="animate-fadeInFromTop text-dt-blue dark:text-white text-3xl font-semibold capitalize tracking-wide mx-4">
              Our Faculty Members
            </p>
            <svg
              width="91"
              height="12"
              viewBox="0 0 91 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M90.7735 6L85 0.226497L79.2265 6L85 11.7735L90.7735 6ZM0 7L85 7V5L0 5V7Z"
                fill="#FFA2A2"
              />
            </svg>
          </div>
          <div className="text-center pb-10">
            <p className="mt-2 text-dt-blue dark:text-white text-lg font-semibold capitalize tracking-wide mx-16">
              We have proudly assigned faculty members. Looking back, here are faculty members of CSI!
            </p>
          </div>
          <div className="flex flex-wrap justify-center">
            {facultyData.map((profile, index) => (
              <div
                key={index}
                className="max-w-lg rounded overflow-hidden shadow-lg m-4" data-aos="fade" data-aos-duration="2000"
              >
                <div className="w-full h-80 bg-gray-200 flex items-center justify-center">
                  <img
                    className="h-full w-full object-cover m-1"
                    src={profile.image}
                    alt={profile.name}
                  />
                </div>
                <div className="flex items-center justify-center m-1">
                  <div className="border border-gray-300 p-2 rounded-lg mr-2">
                    <div className="flex flex-col text-center item-center justify-center">
                      <h2 className="text-lg font-semibold text-dt-blue dark:text-white">{profile.name}</h2>
                      <h3 className="text-md text-dt-blue dark:text-white">{profile.position}</h3>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>

  )
}
