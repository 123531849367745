import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

export const EventDetails = () => {
  const { eventYear, eventId } = useParams();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    fetch('/data/db.json')
      .then(response => response.json())
      .then(data => {
        if (data?.data?.events?.[eventYear]) {
          const yearData = data.data.events[eventYear];
          const foundEvent = yearData.find(event => event.id === eventId);
          setEvent(foundEvent || null);
        }
      })
      .catch(error => console.error("Error fetching data:", error));
  }, [eventYear, eventId]);

  if (!event) {
    return <div>Event not found.</div>;
  }

  return (
    <div className="">
        <div className="container py-[24px] lg:py-[32px]">
        <div className="py-6 px-4 sm:px-6 md:px-8">
          <div className="flex flex-row items-center justify-start sm:justify-around text-center" >
            <div className="flex items-center justify-center mr-8">
              <Link to="/events">
                <div className="group rounded-full bg-white p-1 border border-2 border-dt-blue hover:bg-lt-blue hover:border-lt-blue dark:bg-dt-blue dark:border-lt-blue dark:hover:bg-lt-blue">
                  <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path className="group-hover:stroke-white group-hover:fill-white dark:stroke-white dark:fill-white" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14M5 12l4-4m-4 4 4 4"/>
                  </svg>
                </div>
              </Link>
            </div>
            <div className="flex items-center justify-start sm:mr-52">
              <svg className="my-auto" width="70" height="12" viewBox="0 0 70 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM70 5H6V7H70V5Z" fill="#FFA2A2" />
              </svg>
              <p className="text-dt-blue dark:text-white text-2xl sm:text-3xl font-semibold capitalize tracking-wide mx-2 mt-0 md:mt-0">
                  {event.title}
              </p>
              <svg className="my-auto" width="70" height="12" viewBox="0 0 70 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M69.7735 6L65 0.226497L59.2265 6L65 11.7735L69.7735 6ZM0 7L65 7V5L0 5V7Z" fill="#FFA2A2" />
              </svg>
            </div>

          </div>
          <div className="flex flex-col items-center mt-8 md:mt-12">
            <div className="flex flex-col md:flex-row items-center justify-start">
              <div className="w-full md:w-1/3 lg:w-1/4 rounded-lg mb-4 md:mb-0 md:ml-8" data-aos="fade" data-aos-duration="3000">
                <img src={event.image} alt={event.title} className="w-full h-auto object-cover rounded-lg" />
              </div>
              {event.description && event.description.length > 0 && (
                <div className="w-full md:w-2/3 lg:w-3/5 rounded-lg md:ml-10" data-aos="fade" data-aos-duration="3000">
                  {/* Conditional Buttons for Year 2024-25 and PDF Rules */ }
                  {eventYear === '2024-25' && (event.registrationLink || event.rulesLink) && (
                   <div className="flex flex-col sm:flex-row justify-start items-center mb-8 sm:px-20 lg:px-40 space-y-8 sm:space-y-0 sm:space-x-8">
                      {event.registrationLink && (
                        <a
                          href={event.registrationLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow-lg transition-colors duration-300 text-center"
                        >
                          REGISTER 📌
                        </a>
                      )}

                      {event.rulesLink && (
                        <a
                          href={event.rulesLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow-lg transition-colors duration-300 text-center"
                        >
                          View Rules 📄
                        </a>
                      )}
                    </div>
                  )}

                  <h2 className="text-2xl sm:text-3xl font-bold text-dt-blue dark:text-white">What is <span className="text-lt-blue">{event.title}?</span></h2>
                  <p className="mt-4 text-lg sm:text-xl text-dt-blue dark:text-white">{event.description}</p>
                  {event.details && event.details.length > 0 && (
                    <ul className="mt-4 space-y-2 text-dt-blue dark:text-white" data-aos="fade" data-aos-duration="2000">
                      {event.details.map((detail, index) => (
                        <li key={index} className="flex items-center text-lg">
                          <svg className="mr-2" width="38" height="12" viewBox="0 0 38 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.6667 6C26.6667 8.94552 29.0545 11.3333 32 11.3333C34.9455 11.3333 37.3333 8.94552 37.3333 6C37.3333 3.05448 34.9455 0.666667 32 0.666667C29.0545 0.666667 26.6667 3.05448 26.6667 6ZM0 7H32V5H0V7Z" fill="#FFA2A2"/>
                          </svg>
                          <span>{detail}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {event.images && event.images.length > 0 && (
          <div className="py-6 px-4 sm:px-6 md:px-8">
            <div className="flex flex-col md:flex-row items-center justify-center text-center md:text-left">
              <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM91 5H6V7H91V5Z" fill="#FFA2A2" />
              </svg>
              <p className="text-dt-blue dark:text-white text-2xl sm:text-3xl font-semibold capitalize tracking-wide mx-4 mt-4 md:mt-0">
                {event.title} {'(Images)'}
              </p>
              <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M90.7735 6L85 0.226497L79.2265 6L85 11.7735L90.7735 6ZM0 7L85 7V5L0 5V7Z" fill="#FFA2A2" />
              </svg>
            </div>
            <div className="flex flex-col items-center mt-8">
              <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {event.images.map((image, index) => (
                  <img key={index} src={image} alt={`Event ${event.name}`} className="w-full h-auto rounded-lg shadow-md" data-aos="fade-up" data-aos-duration="1500" />
                ))}
              </div>
            </div>
          </div>
        )}
        {event.videos && event.videos.length > 0 && (
          <div className="py-6 px-4 sm:px-6 md:px-8">
            <div className="flex flex-col md:flex-row items-center justify-center text-center md:text-left">
              <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.226497 6L6 11.7735L11.7735 6L6 0.226497L0.226497 6ZM91 5H6V7H91V5Z" fill="#FFA2A2" />
              </svg>
              <p className="text-dt-blue dark:text-white text-2xl sm:text-3xl font-semibold capitalize tracking-wide mx-4 mt-4 md:mt-0">
                {event.title} {'(Videos)'}
              </p>
              <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M90.7735 6L85 0.226497L79.2265 6L85 11.7735L90.7735 6ZM0 7L85 7V5L0 5V7Z" fill="#FFA2A2" />
              </svg>
            </div>
            <div className="flex flex-col items-center mt-8">
              <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {event.videos.map((video, index) => (
                  <video controls className="w-full h-auto rounded-lg shadow-md" data-aos="fade-up" data-aos-duration="2000" src={video} key={index}></video>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
